.ibanera-signup {
  .left-content {
    // width: 60%;
    @media (max-width: 992px) {
      width: 100%;
    }
    .content {
      max-width: calc(95% - 140px) !important;
      @media (max-width: 992px) {
        max-width: calc(90% - 0px) !important;
      }
      .signup-form {
        max-width: 580px;
        width: 100%;
        @media (max-width: 992px) {
          max-width: 100%;
        }
        h3 {
          font-size: 30px;
          line-height: 38px;
          font-family: "Mona-SemiBold";
          color: #101828;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          font-family: "Mona-Regular";
          color: #475467;
          margin-block: 8px 16px;
        }
        form {
          .main-pass {
            position: relative;
            .pass-eye {
              position: absolute;
              right: 15px;
              width: 17px;
              top: 38px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.signup-form {
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    label {
      color: #344054;
      font-family: "Mona-Medium";
      font-size: 14px;
      line-height: 20px;
      display: inline-block;
      margin-bottom: 6px;
      a {
        color: #344054;
      }
      span {
        font-family: "Mona-Medium";
      }
    }
    input.form-control,
    .select-phone,
    textarea.form-control {
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      background: #fff;
      padding: 0 14px;
      height: 44px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      transition: border 0.5s ease;
      width: 100%;
      &::placeholder {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        flex: 1 0 0;
        overflow: hidden;
        color: #667085;
        text-overflow: ellipsis;
        font-family: "Mona-Regular";
        font-size: 16px;
        line-height: 24px;
      }

      &:hover {
        border: 1px solid #1943dc;
      }
      &.error-input {
        border-color: #f00;
        &:hover {
          border-color: #f00;
        }
      }
    }
    .error {
      color: #f00;
      font-size: 12px;
      padding-left: 5px;
    }
    .Name {
      display: flex;
      align-items: flex-start;
      gap: 32px;
      width: 100%;
      @media (max-width: 580px) {
        gap: 22px;
        flex-direction: column;
      }
      div {
        width: 100%;
      }
    }
    .email {
      width: 100%;
    }
    .message {
      width: 100%;
    }
    .select-phone {
      width: 100%;
      .PhoneInput {
        height: 100%;
        input {
          border: none;
          min-width: 40px;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-repeat: no-repeat;
          background-position: center right;
          color: #344054;
          font-family: "Mona-Regular";
          font-size: 16px;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          // }
          input {
            border: none;
            box-shadow: none;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .error {
        padding-left: 0px;
        margin-left: -10px;
      }
    }
    .password,
    .confirm-password {
      width: 100%;
    }
    .password {
      .password-check {
        padding-top: 5px;
        .line {
          width: 100%;
          background: rgba(221, 221, 221, 1);
          height: 2px;
          position: relative;
          transition: 0.3s linear;
          span {
            display: inline-block;
            // width: 75%;
            background: #1943dc;
            height: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.3s linear;
          }
        }
        .strong-pass {
          display: flex;
          align-items: center;
          gap: 5px;
          padding-top: 5px;
          span {
            color: #475467;
            font-size: 14px;
            line-height: 20px;
            font-family: "Mona-Regular";
          }
        }
      }
    }
    button {
      margin-top: 8px;
    }
  }
}
