.auth-content {
  display: flex;
  .left-content {
    width: 50%;
    @media (max-width: 992px) {
      width: 100%;
      min-height: 100vh;
    }
    .content {
      max-width: 360px;
      width: 100%;
      margin-left: 140px;
      padding-block: 50px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 50px;
      @media (max-width: 1200px) {
        margin-left: 100px;
      }
      @media (max-width: 992px) {
        max-width: 95%;
        margin-inline: auto;
        align-items: center;
      }
      .header {
        width: 100%;
      }
      form {
        width: 100%;
        h3 {
          font-size: 30px;
          line-height: 38px;
          font-family: "Mona-SemiBold";
          color: #101828;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          font-family: "Mona-Regular";
          color: #475467;
          margin-block: 8px 16px;
        }
        .input {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-bottom: 20px;
          position: relative;
          label {
            color: #344054;
            font-size: 14px;
            line-height: 20px;
            font-family: "Mona-Medium";
          }
          input {
            border: 1px solid #d0d5dd;
            border-radius: 8px;
            height: 44px;
            padding: 15px;
            color: #667085;
            font-size: 16px;
            line-height: 24px;
            font-family: "Mona-Regular";
            &:hover {
              border: 1px solid #1943dc;
            }
            &.error-input {
              border-color: #f00;
              &:hover {
                border-color: #f00;
              }
            }
          }
          .pass-eye {
            position: absolute;
            right: 15px;
            width: 17px;
            top: 38px;
            cursor: pointer;
          }
          .error {
            color: #f00;
            font-size: 12px;
            padding-left: 5px;
          }
          span {
            color: #475467;
            font-size: 14px;
            line-height: 20px;
            font-family: "Mona-Regular";
          }
        }
        .form-check {
          display: flex;
          justify-content: space-between;
          width: 100%;
          div {
            display: flex;
            gap: 8px;

            label {
              color: #344054;
              font-size: 14px;
              line-height: 20px;
              font-family: "Mona-Medium";
            }
            .form-check-input {
              width: 16px;
              height: 20px;
            }
          }
          a {
            font-size: 14px;
            line-height: 20px;
            font-family: "Mona-SemiBold";
            color: #1943dc;
            text-decoration: none;
          }
        }
        button {
          width: 100%;
          justify-content: center;
          margin-top: 20px;
        }
        h5 {
          font-size: 14px;
          line-height: 20px;
          font-family: "Mona-Regular";
          color: #475467;
          margin-top: 20px;
          text-align: center;
          a {
            font-family: "Mona-SemiBold";
            color: #1943dc;
            text-decoration: none;
          }
        }
      }
      h6 {
        font-size: 14px;
        line-height: 20px;
        font-family: "Mona-Regular";
        color: #667085;
      }
    }
  }
  .right-content {
    background: url("../../../assets/images/auth/authBg1.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 50%;
    min-height: 100vh;
    background-position: right bottom;
    position: relative;
    @media (max-width: 992px) {
      display: none;
    }
    .auth-animation {
      position: absolute;
      bottom: -5px;
      width: 100%;
    }
  }
}
