.fieldContainer {
  width: 100%;
  position: relative;
  z-index: 5;

  .select-country {
    width: 100%;
    .css-1u9des2-indicatorSeparator {
      display: none;
    }

    input {
      height: auto;

      &::placeholder {
        font-family: "Mona-Medium";
        color: rgba(#667085, 40%);
      }
    }
  }
}

.bancolombia_footer {
  display: flex;
  height: -webkit-fill-available;
  position: relative;
  gap: 10px;
  .legal_vertical {
    width: 50px;
    max-height: 350px;
  }
  .plazamail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .check {
      display: flex;
      align-items: flex-start;
      gap: 3px;

      input {
        margin-top: 3px;
      }
    }
  }
}

// .p-block-none {
//   .css-1fdsijx-ValueContainer {
//     padding-block: 0 !important;
//   }
//   .css-qbdosj-Input {
//     padding-block: 0 !important;
//     margin-block: 0 !important;
//   }
//   .css-3w2yfm-ValueContainer {
//     padding-block: 0 !important;
//   }
// }
