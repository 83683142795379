.primary-button {
  background-color: #1943dc;
  border-radius: 4px;
  border: 2px solid #1943dc;
  color: #ffffff;
  font-size: 16px;
  font-family: "Mona-SemiBold";
  line-height: 15px;
  padding: 11px 15px;
  cursor: pointer;
  transition: 0.3s linear;
  display: flex;
  align-items: center;
  height: 42px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &.marginTop {
    margin-top: 24px;
  }
  &.full {
    width: 100%;
    justify-content: center;
  }
  span {
    padding-left: 12px;
  }
  &.disabled {
    background-color: rgba(#1943dc, 0.4);
    border: 1px solid #eaecf0;
    color: #fff !important;
    cursor: not-allowed;
    box-shadow: 0px 1px 2px 0 rgba(16, 24, 40, 5%);
    &:hover {
      span {
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
      &::after,
      &::before {
        display: none;
      }
    }
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: #fff;
    transition: 0.6s ease-out;
    z-index: -1;
  }
  &::before {
    content: "";
    left: -2px;
  }
  &::after {
    content: "";
    right: -2px;
  }
  &:hover {
    color: #1943dc;
    span {
      svg {
        path {
          stroke: #1943dc;
        }
      }
    }
    &::before {
      width: 52%;
    }
    &::after {
      width: 52%;
    }
  }
  @media (max-width: 580px) {
    font-size: 14px;
  }
}
