.enable2faClass {
    display: flex;
    .left-content {
      width: 50%;
      @media (max-width: 992px) {
        width: 100%;
        min-height: 100vh;
      }
      .content {
        max-width: 360px;
        width: 100%;
        margin-left: 140px;
        padding-block: 50px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 50px;
        @media (max-width: 1200px) {
          margin-left: 100px;
        }
        @media (max-width: 992px) {
          max-width: 95%;
          margin-left: 0;
          padding-left: 30px;
        }
        .header {
          width: 100%;
        }
        form {
          @media (max-width: 992px) {
            max-width: 360px;
            margin-inline: auto;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
          @media (max-width: 450px) {
            max-width: 290px;
          }
          h3 {
            font-size: 30px;
            line-height: 38px;
            font-family: "Mona-SemiBold";
            color: #101828;
            @media (max-width: 450px) {
              font-size: 24px;
              line-height: 32px;
            }
          }
          p {
            font-size: 16px;
            line-height: 24px;
            font-family: "Mona-Regular";
            color: #475467;
            margin-block: 8px 16px;
            @media (max-width: 450px) {
              font-size: 15px;
              line-height: 28px;
            }
          }
          .input {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-bottom: 20px;
            label {
              color: #344054;
              font-size: 14px;
              line-height: 20px;
              font-family: "Mona-Medium";
            }
            input {
              border: 1px solid #d0d5dd;
              border-radius: 8px;
              height: 44px;
              padding: 15px;
              color: #667085;
              font-size: 16px;
              line-height: 24px;
              font-family: "Mona-Regular";
              &:hover {
                border: 1px solid #1943dc;
              }
            }
            span {
              color: #475467;
              font-size: 14px;
              line-height: 20px;
              font-family: "Mona-Regular";
            }
          }
          .form-check {
            display: flex;
            justify-content: space-between;
            width: 100%;
            div {
              display: flex;
              gap: 8px;
  
              label {
                color: #344054;
                font-size: 14px;
                line-height: 20px;
                font-family: "Mona-Medium";
              }
              .form-check-input {
                width: 16px;
                height: 20px;
              }
            }
            a {
              font-size: 14px;
              line-height: 20px;
              font-family: "Mona-SemiBold";
              color: #1943dc;
              text-decoration: none;
            }
          }
          button {
            width: 100%;
            justify-content: center;
            margin-top: 20px;
          }
          h5 {
            font-size: 14px;
            line-height: 20px;
            font-family: "Mona-Regular";
            color: #475467;
            margin-top: 20px;
            // text-align: left;
            a {
              display: flex;
              justify-content: center;
              gap: 10px;
              font-family: "Mona-SemiBold";
              color: #1943dc;
              text-decoration: none;
            }
          }
        }
        h6 {
          font-size: 14px;
          line-height: 20px;
          font-family: "Mona-Regular";
          color: #667085;
        }
        .verification-code {
          color: #1943dc;
          font-size: 14px;
          font-family: "Mona-Medium";
          margin-top: 40px;
          margin-bottom: 5px;
          @media (max-width: 450px) {
              margin-top: 15px;
          }
        }
        .otp-code {
          display: flex;
          align-items: center;
          gap: 15px;
          justify-content: center;
          input {
            width: 48px;
            height: 48px;
            border: 1px solid #d0d5dd;
            border-radius: 5px;
            padding: 10px;
            text-align: center;
            font-size: 14px;
            color: #101828;
            font-family: "Mona-Medium";
            box-shadow: 0px 0px 12px rgba(#d0d5dd, 10%);
            transition: 0.3s linear;
            @media (max-width: 450px) {
              width: 35px;
              height: 35px;
            }
            &.error {
              border: 1px solid #f00;
              &:hover {
                border: 1px solid #f00;
              }
            }
            &:hover {
              border: 1px solid #1943dc;
            }
            &:focus {
              border: 1px solid #1943dc;
            }
          }
        }
      }
    }
    .right-content {
      // background: url("../../../assets/images/auth/authBg1.svg");
      background: url("../../../../assets/images/auth/authBg1.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      width: 50%;
      min-height: 100vh;
      background-position: right bottom;
      position: relative;
      @media (max-width: 992px) {
        display: none;
      }
      .auth-animation {
        position: absolute;
        bottom: -5px;
        width: 100%;
      }
    }
  }
  