.authentication-screen {
  .left-content {
    .content {
      padding-left: 140px !important;
      margin-left: 0 !important;
      padding-right: 40px;
      max-width: 100% !important;
      @media (max-width: 992px) {
        padding-left: 40px;
      }
      @media (max-width: 600px) {
        padding-inline: 20px;
      }
      form {
        .account-type {
          flex-direction: row;
          @media (max-width: 500px) {
            flex-direction: column;
          }
          .Heading {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            img {
              width: 50%;
            }
            .left {
              padding-top: 30px;
            }
          }
        }
        button {
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

.two-factor {
  .left-content {
    .content {
      .header {
        &.sms-auth{
          form{
            margin-top: 150px;
          }
        }
        form {
          .account-type {
            flex-direction: row;
            @media (max-width: 500px) {
              flex-direction: column;
            }
            .Heading {
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              img {
                width: 50%;
              }
              .left {
                padding-top: 30px;
              }
            }
          }
          button {
            max-width: 100%;
            margin-left: 0;
          }
          .select-phone {
            border-radius: 8px;
            border: 1px solid #d0d5dd;
            background: #fff;
            padding: 0 14px;
            height: 44px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            transition: border 0.5s ease;
            width: 100%;
            &::placeholder {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              flex: 1 0 0;
              overflow: hidden;
              color: #667085;
              text-overflow: ellipsis;
              font-family: "Mona-Regular";
              font-size: 16px;
              line-height: 24px;
            }
            .PhoneInput {
              height: 100%;
              input {
                border: none;
                min-width: 40px;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                background-repeat: no-repeat;
                background-position: center right;
                color: #344054;
                font-family: "Mona-Regular";
                font-size: 16px;
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
                // }
                input {
                  border: none;
                  box-shadow: none;
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
            .error {
              padding-left: 0px;
              margin-left: -10px;
            }
            &:hover {
              border: 1px solid #1943dc;
            }
            &.error-input {
              border-color: #f00;
              &:hover {
                border-color: #f00;
              }
            }
          }
        }
        .instead-class {
          cursor: pointer;
          text-align: right;
          display: inline-block;
          padding-top: 7px;
          font-size: 12px;
          text-decoration: underline;
          color: #1943dc;
          font-family: "Mona-Medium";
          width: 100%;
        }
      }
    }
  }
}
