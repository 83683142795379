footer {
  background: #f6f9fc;
  font-family: "Mona-Regular";
  height: 100%;
  padding: 100px 0 30px;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
  .container {
    .footer-upper-grid {
      padding-bottom: 70px;
      display: flex;
      align-items: flex-start;
      gap: 30px;
      @media (max-width: 992px) {
        flex-direction: column;
      }
      .footer-upper-left-info {
        max-width: 520px;
        width: 100%;
        @media (max-width: 992px) {
          max-width: 100%;
        }

        h3 {
          letter-spacing: -0.2px;
          color: #00112c;
          font-size: 36px;
          font-family: "Mona-SemiBold";
          transition: 0.3s linear;
          @media (max-width: 768px) {
            font-size: 28px;
          }
        }
        p {
          line-height: 28px;
          max-width: 412px;
          width: 100%;
          margin-top: 15px;
          letter-spacing: 0.2px;
          color: #425466;
          font-size: 18px;
          font-family: "Mona-Medium";
          transition: 0.3s linear;
          @media (max-width: 992px) {
            max-width: 100%;
            font-size: 16px;
            line-height: 26px;
            margin-top: 12px;
          }
        }
      }
      .footer-right-content {
        display: flex;
        gap: 30px;
        width: 100%;
        transition: 0.3s linear;
        @media (max-width: 768px) {
          flex-direction: column;
        }
        .footer-upper-center-info,
        .footer-upper-right-info {
          max-width: 270px;
          width: 100%;
          letter-spacing: 0.2px;
          transition: 0.3s linear;
          @media (max-width: 992px) {
            max-width: 100%;
          }
          h5 {
            color: #0a2540;
            font-size: 14px;
            font-family: "Mona-SemiBold";
            margin-top: 10px;
            position: relative;
            transition: 0.3s linear;
            @media (max-width: 992px) {
              font-size: 12px;
            }
            &::before {
              content: "";
              position: absolute;
              width: 1px;
              height: 15px;
              top: 0;
              left: -15px;
              background-color: #1943dc;
            }
          }
          p {
            width: 100%;
            max-width: 222px;
            font-size: 14px;
            color: #425466;
            font-family: "Mona-Medium";
            line-height: 28px;
            margin-top: 7px;
            margin-bottom: 10px;
            transition: 0.3s linear;
            @media (max-width: 992px) {
              font-size: 12px;
              max-width: 100%;
            }
          }
          a {
            text-decoration: none;
            font-size: 14px;
            font-family: "Mona-Medium";
            color: #1943dc;
            line-height: 18px;
            transition: 0.3s linear;
            @media (max-width: 992px) {
              font-size: 12px;
            }
            span {
              transition: 0.1s linear;
              padding-left: 6px;
            }
            &:hover {
              span {
                padding-left: 10px;
                img {
                  transform: scale(1.2);
                }
              }
            }
          }
        }
      }
    }
    .footer-cta {
      text-align: center;
      width: 100%;
      // min-height: 575px;
      position: relative;
      // box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s linear;
      @media (max-width: 768px) {
        cursor: pointer;
      }
      @media (max-width: 992px) {
        // background-image: url("../../assets/images/footerSocialIcons/footerCTA.png");
        // background-image: url("../../assets/images/about/ctaBg.svg");
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;
        // min-height: 500px;
        // padding-bottom: 70px;
        // padding-inline: 10px;
        border-radius: 22px;
      }
      // .cta-img {
      //   @media (min-width: 992px) {
      //     display: none;
      //   }
      //   width: 100%;
      // }
      .lotify {
        // @media (max-width: 992px) {
        //   display: none;
        // }
        div {
          svg {
            border-radius: 22px;
          }
        }
      }
      .footer-cta-content {
        position: absolute;
        left: 50%;
        top: 65%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding-inline: 20px;
        @media (max-width: 768px) {
          top: 55%;
        }
        h3 {
          margin-inline: auto;
          font-size: 42px;
          font-family: "Mona-Bold";
          letter-spacing: -0.23px;
          line-height: 45.6px;
          color: #ffffff;
          max-width: 450px;
          width: 100%;
          transition: 0.3s linear;
          // @media (max-width: 768px) {
          @media (max-width: 992px) {
            margin-top: 40px;
            font-size: 28px;
            line-height: 35.6px;
            max-width: 100%;
          }
          @media (max-width: 650px) {
            font-size: 22px;
            line-height: 28px;
          }
          @media (max-width: 480px) {
            font-size: 16px;
            line-height: 20px;
          }
          @media (max-width: 400px) {
            margin-top: 20px;
          }
        }
        p {
          margin-inline: auto;
          font-size: 20px;
          line-height: 32px;
          letter-spacing: 0.23px;
          color: #ffffff;
          max-width: 537px;
          width: 100%;
          margin-block: 15px 30px;
          opacity: 70%;
          // @media (max-width: 768px) {
          @media (max-width: 992px) {
            font-size: 14px;
            line-height: 20px;
            max-width: 100%;
            margin-block: 15px;
          }
          @media (max-width: 650px) {
            margin-block: 6px;
            line-height: 20px;
          }
          @media (max-width: 480px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        button {
          margin-inline: auto;
          color: #1943dc;
          border: 1px solid #1943dc;
          @media (max-width: 768px) {
            display: none;
          }
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .footer-links {
      padding-top: 140px;
      display: flex;
      justify-content: space-between;
      gap: 40px;
      transition: 0.3s linear;
      @media (max-width: 1024px) {
        gap: 15px;
      }
      @media (max-width: 992px) {
        flex-direction: column;
        gap: 30px;
        padding-top: 80px;
      }
      .footer-logo-desc {
        max-width: 320px;
        width: 100%;
        transition: 0.3s linear;
        @media (max-width: 992px) {
          max-width: 100%;
        }
        p {
          color: #475467;
          font-size: 16px;
          font-family: "Mona-Regular";
          line-height: 24px;
          transition: 0.3s linear;
          margin-top: 30px;
          @media (max-width: 768px) {
            margin-top: 15px;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      .footer-links-cols {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 15px;
        @media (max-width: 768px) {
          flex-wrap: wrap;
        }
        .links {
          width: 100%;
          transition: 0.3s linear;
          display: flex;
          flex-direction: column;
          @media (max-width: 768px) {
            width: 45%;
          }
          @media (max-width: 500px) {
            width: 100%;
          }
          &:first-child {
            max-width: 185px;
            @media (max-width: 768px) {
              max-width: 100%;
            }
          }
          &:nth-child(2) {
            max-width: 140px;
            @media (max-width: 768px) {
              max-width: 100%;
            }
          }
          &:nth-child(3) {
            max-width: 136px;
            @media (max-width: 768px) {
              max-width: 100%;
            }
          }
          &:last-child {
            max-width: 190px;
            @media (max-width: 768px) {
              max-width: 100%;
            }
          }
          h5 {
            color: #667085;
            font-size: 14px;
            line-height: 20px;
            font-family: "Mona-SemiBold";
            transition: 0.3s linear;
            margin-bottom: 16px;
            @media (max-width: 768px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
          a {
            color: #475467;
            font-size: 16px;
            line-height: 20px;
            font-family: "Mona-SemiBold";
            text-decoration: none;
            transition: 0.3s linear;
            padding-bottom: 15px;
            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }

    .footer-bottom {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #ddd3d3;
      padding-top: 30px;
      transition: 0.3s linear;
      gap: 30px;
      @media (max-width: 768px) {
        margin-top: 30px;
      }
      span {
        color: #667085;
        font-size: 16px;
        font-family: "Mona-Regular";
        transition: 0.3s linear;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        justify-content: center;
      }
      .footer-social-icons {
        list-style-type: none;
        display: flex;
        align-items: center;
        gap: 24px;
        transition: 0.3s linear;
        li {
          svg {
            path {
              transition: 0.3s ease;
            }
          }
          &:hover {
            animation: shake 0.4s ease;
            .linkedin-icon {
              path {
                fill: #0072b1;
              }
            }
            .facebook-icon {
              path {
                fill: #039be5;
              }
            }
            .dribble-icon {
              path {
                fill: #bd1949;
              }
            }
            .twitter-icon,
            .github-icon,
            .victory-icon {
              path {
                fill: #000000;
              }
            }
          }
        }
        @media (max-width: 768px) {
          gap: 20px;
        }
      }
    }
    .footer-content {
      text-align: center;
      padding-top: 50px;
      @media (max-width: 768px) {
        padding-top: 30px;
      }

      .text {
        p {
          font-size: 12px;
          font-family: "Mona-Medium";
          line-height: 18px;
          color: #667085;
          &:not(:last-child) {
            padding-bottom: 10px;
          }
        }
      }
      .bank-imgs {
        display: flex;
        gap: 20px;
        // justify-content: flex-end;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 30px;
        div {
          &.cert {
            img {
              @media (max-width: 768px) {
                height: 40px;
                width: auto;
              }
            }
          }
          display: flex;
          align-items: center;
          gap: 25px;
          @media (max-width: 768px) {
            gap: 10px;
          }
        }
        @media (max-width: 768px) {
          gap: 10px;
          justify-content: center;
        }
        img {
          height: 40px;
          width: auto;
        }
      }
    }
  }
}

// social media icons animation
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-3px) rotate(3deg);
  }
  50% {
    transform: translateX(3px) rotate(-3deg);
  }
  75% {
    transform: translateX(-3px) rotate(3deg);
  }
  100% {
    transform: translateX(0);
  }
}
