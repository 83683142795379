nav {
  border-bottom: 1px solid rgba(0, 0, 0, 7%);
  padding-block: 20px;
  width: 100%;
  top: 0;
  position: fixed;
  background-color: transparent;
  z-index: 99;
  height: 80px;
  transition: 0.3s ease;
  &.scroll-color {
    height: 65px;
    padding-block: 12px;
    background-color: #f6f9fc;
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    .nav-items {
      display: block;
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 32px;
        li {
          font-size: 16px;
          font-family: "Mona-Medium";
          color: #51525c;
          cursor: pointer;
          a {
            transition: 0.3s ease;
            color: #51525c;
            text-decoration: none;
          }
        }
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
    .nav-btns {
      display: flex;
      align-items: center;
      gap: 12px;
      @media (max-width: 992px) {
        display: none;
      }
      a {
        text-decoration: none;
        font-size: 14px;
        font-family: "Mona-SemiBold";
        border-radius: 4px;
        padding: 10px 15px;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        z-index: 1;
        &.login {
          background-color: #ffffff;
          color: #060a23;
          border: 1px solid rgba(0, 0, 0, 5%);
          &::after,
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 100%;
            background: #1943dc;
            transition: 0.6s ease-out;
            z-index: -1;
            top: 0;
          }
          &::before {
            content: "";
            left: 0px;
          }
          &::after {
            content: "";
            right: 0px;
          }
          &:hover {
            color: #ffffff;
            &::before {
              width: 52%;
            }
            &::after {
              width: 52%;
            }
          }
        }
        &.signup {
          background-color: #1943dc;
          color: #ffffff;
          border: 1px solid #1943dc;
          &::after,
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 100%;
            background: #fff;
            transition: 0.6s ease-out;
            z-index: -1;
            top: 0;
          }
          &::before {
            content: "";
            left: 0px;
          }
          &::after {
            content: "";
            right: 0px;
          }
          &:hover {
            color: #1943dc;
            &::before {
              width: 52%;
            }
            &::after {
              width: 52%;
            }
          }
        }
      }
    }
    .nav-item-mobile {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 5px;
      @media (min-width: 1024px) {
        display: none;
      }
      cursor: pointer;
      &.animate {
        span {
          &:nth-child(2) {
            opacity: 0;
          }
          &:first-child {
            width: 20px;
            transform: rotate(-45deg);
            transform-origin: 15px 0px;
          }
          &:last-child {
            transform: rotate(45deg);
            transform-origin: 27px -1px;
            width: 20px;
          }
        }
      }
      span {
        height: 3px;
        border-radius: 10px;
        background-color: #333;
        width: 30px;
        transition: 0.3s ease-out;
        &:last-child {
          width: 10px;
        }
        &:nth-child(2) {
          width: 20px;
        }
      }
    }
  }
}
.mbl-menu {
  overflow: hidden;
  position: fixed;
  width: 350px;
  min-height: 100vh;
  right: -400px;
  transition: 0.3s linear;
  background-color: #ffffff;
  z-index: 999;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 3%);
  padding: 30px;
  &.toggle-menu {
    right: 0;
  }
  @media (max-width: 380px) {
    width: 100%;
  }
  .mbl-nav-items {
    .header {
      display: flex;
      align-items: center;
      gap: 30px;
      justify-content: space-between;
      .header-no-animate {
        transition: 0.2s ease-in;
        transition-delay: 0.3s;
        opacity: 0;
        margin-left: -100px;
      }
      .header-animate {
        opacity: 1;
        margin-left: 0px;
      }
      .close-menu {
        cursor: pointer;
        &.close-no-animate {
          transition: 0.2s ease-in;
          transition-delay: 0.6s;
          opacity: 0;
          margin-right: -100px;
        }
        &.close-animate {
          opacity: 1;
          margin-right: 0px;
        }
      }
    }
    ul {
      list-style: none;
      margin-block: 40px;
      li {
        overflow: hidden;
        &.no-animate {
          transition: 0.2s ease-in;
          transition-delay: 0.3s;
          opacity: 0;
          margin-left: -100px;
          &:nth-child(2) {
            transition-delay: 0.3s;
          }
          &:nth-child(3) {
            transition-delay: 0.4s;
          }
          &:nth-child(4) {
            transition-delay: 0.5s;
          }
          &:nth-child(5) {
            transition-delay: 0.6s;
          }
          &:nth-child(6) {
            transition-delay: 0.7s;
          }
        }
        &.animate-item {
          opacity: 1;
          margin-left: 0px;
        }
        padding-block: 10px;
        cursor: pointer;
        &.mbl-dropdown {
          position: relative;
          .mbl-dropdown-content {
            background-color: #fff;
            box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
            min-width: 170px;
            border-radius: 6px;
            padding-block: 0px;
            margin: 0;
            padding-inline: 15px;
            transition: 0.2s linear;
            height: 0;
            opacity: 0;
            overflow: hidden;
            top: -200px;
            &.open {
              height: 100%;
              top: 0;
              opacity: 1;
              margin-top: 10px;
              padding-block: 5px;
              li {
                padding-block: 5px;
              }
            }
            li {
              a {
                font-size: 14px;
                font-family: "Mona-Medium";
              }
            }
          }
        }
        a {
          text-decoration: none;
          color: #060a23;
          font-family: "Mona-Medium";
        }
      }
    }
  }
  .mbl-nav-btns {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: column;
    a {
      width: 100%;
      font-size: 14px;
      font-family: "Mona-SemiBold";
      border-radius: 4px;
      padding: 10px 15px;
      white-space: nowrap;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 1;
      &.login {
        background-color: transparent;
        color: #060a23;
        border: 1px solid rgba(0, 0, 0, 5%);
        &::after,
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          background: #1943dc;
          transition: 0.6s ease-out;
          z-index: -1;
          top: 0;
        }
        &::before {
          content: "";
          left: 0px;
        }
        &::after {
          content: "";
          right: 0px;
        }
        &:hover {
          color: #ffffff;
          &::before {
            width: 52%;
          }
          &::after {
            width: 52%;
          }
        }
      }
      &.signup {
        background-color: #1943dc;
        color: #ffffff;
        border: 1px solid #1943dc;
        &::after,
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 100%;
          background: #fff;
          transition: 0.6s ease-out;
          z-index: -1;
          top: 0;
        }
        &::before {
          content: "";
          left: 0px;
        }
        &::after {
          content: "";
          right: 0px;
        }
        &:hover {
          color: #1943dc;
          &::before {
            width: 52%;
          }
          &::after {
            width: 52%;
          }
        }
      }
      &.login-no-animate {
        transition: 0.2s ease-in;
        opacity: 0;
        transition-delay: 0.8s;
        margin-right: -100px;
      }
      &.login-animate {
        opacity: 1;
        margin-right: 0px;
      }
      &.signup-no-animate {
        transition: 0.2s ease-in;
        opacity: 0;
        transition-delay: 1s;
        margin-left: -100px;
      }
      &.signup-animate {
        opacity: 1;
        margin-left: 0px;
      }
    }
  }
}

.custom-nav-dropdown {
  position: relative;
}

.dropdown-icon {
  margin-left: 5px;
}
.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  transition: 0.2s linear;
  position: absolute;
  opacity: 0;
  top: -30px;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);
  min-width: 170px;
  border-radius: 6px;
  padding-block: 6px;
  pointer-events: none;
  &.open {
    pointer-events: all;
    opacity: 1;
    top: 30px;
  }
  a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #51525c;
    font-size: 14px;
    font-family: "Mona-Medium";
    &:hover {
      background-color: #f4f4f4;
    }
  }
}

.dropdown-toggle:focus + .dropdown-content,
.dropdown-content:focus-within {
  display: block;
}
