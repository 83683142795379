.forgot .left-content .content form .resend-text {
  color: #1943dc;
  text-decoration: underline;
  justify-content: flex-start;
}
.forgot .left-content .content form {
  padding-top: 40px;
}
.verify-email {
  .left-content {
    .content {
      justify-content: center !important;
      form{
        .verification-code {
          color: #1943dc;
          font-size: 14px;
          font-family: "Mona-Medium";
          // margin-top: 40px;
          margin-bottom: 5px;
          @media (max-width: 450px) {
              margin-top: 15px;
          }
        }
        .otp-code {
          display: flex;
          align-items: center;
          gap: 15px;
          justify-content: center;
          input {
            width: 44px !important;
            height: 44px;
            border: 1px solid #d0d5dd;
            border-radius: 5px;
            padding: 10px;
            text-align: center;
            font-size: 14px;
            color: #101828;
            font-family: "Mona-Medium";
            box-shadow: 0px 0px 12px rgba(#d0d5dd, 10%);
            transition: 0.3s linear;
            @media (max-width: 450px) {
              width: 35px;
              height: 35px;
            }
            &.error {
              border: 1px solid #f00;
              &:hover {
                border: 1px solid #f00;
              }
            }
            &:hover {
              border: 1px solid #1943dc;
            }
            &:focus {
              border: 1px solid #1943dc;
            }
          }
        }
      }
    }
  }
}
