.account-type {
  padding-top: 40px;
  border-top: 1px solid #eaecf0;
  margin-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .card {
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid #eaecf0;
    background: #fff;
    padding: 24px;
    justify-content: space-between;
    box-shadow: none;
    width: 100%;
    transition: 0.3s ease;
    position: relative;
    &.active {
      border: 1px solid #1943dc;
      background-color: rgba(98, 112, 245, 2%);
    }
    &:hover {
      box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.1);
    }
    @media (max-width: 992px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      gap: 15px;
      padding: 15px;
    }
    &:last-child {
      margin: 0px;
    }
    .account-type-content {
      .Heading {
        display: flex;
        gap: 8px;
        align-items: flex-start;
        margin-bottom: 8px;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 600px) {
          flex-direction: column;
        }
        .left {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          h4 {
            color: #101828;
            font-family: "Mona-SemiBold";
            font-size: 18px;
            line-height: 28px;
            text-align: left;
          }
          ul.tags {
            margin: 0px;
            li {
              color: #344054;
              font-family: "Mona-Medium";
              font-size: 14px;
              line-height: 20px;
              border-radius: 6px;
              border: 1px solid #d0d5dd;
              background: #fff;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              padding: 2px 8px;
              display: flex;
              gap: 6px;
              align-items: center;
              white-space: nowrap;
              .bg-label-circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #2e90fa;
              }
            }
          }
        }
        .country {
          display: inline-flex;
          // position: absolute;
          // top: 24px;
          // right: 24px;
          gap: 6px;
          padding: 2px 6px;
          border-radius: 6px;
          border: 1px solid #d0d5dd;
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          align-items: center;
          // @media (max-width: 768px) {
          //   top: 15px;
          //   right: 15px;
          // }

          h4 {
            color: #344054;
            font-family: "Mona-Medium";
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .Desc {
        p {
          color: #475467;
          font-family: "Mona-Regular";
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
      }
      ul.Perks {
        margin: 0px;
        margin-top: 32px;

        display: flex;
        gap: 20px;
        align-items: center;
        align-self: stretch;
        li {
          display: flex;
          align-items: center;
          color: #475467;
          font-family: "Mona-Medium";
          font-size: 16px;
          line-height: 24px;
          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
