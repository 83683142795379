.main-content {
  padding-top: 80px;
}
.company-slider {
  margin-bottom: 50px;
  .slider-img {
    height: 40px;
    img {
      object-fit: scale-down;
      width: 100%;
      height: 100%;
    }
  }
}
.privacy-hero {
  background-color: #f6f9fc;
  min-height: 400px;
  display: flex;
  align-items: center;
  .hero-left-heading-content {
    max-width: 623px;
    width: 100%;
    @media (max-width: 992px) {
      max-width: 100%;
    }
    .footer-update {
      span {
        font-size: 14px;
        font-family: "Mona-Medium";
        line-height: 14px;
        letter-spacing: 0.2px;
        color: #1943dc;
        transition: 0.3s linear;
        display: flex;
        align-items: center;
        gap: 10px;
        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    h2 {
      color: #00112c;
      font-size: 64px;
      font-family: "Mona-SemiBold";
      line-height: 88px;
      letter-spacing: -3px;
      transition: 0.3s linear;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 0.2px;
      }
    }
    p {
      width: 100%;
      color: #425466;
      font-size: 18px;
      font-family: "Mona-Medium";
      line-height: 28px;
      letter-spacing: 0.2px;
      transition: 0.3s linear;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
    .hero-btns {
      margin-top: 25px;
      display: flex;
      align-items: center;
      gap: 15px;
      @media (max-width: 460px) {
        flex-direction: column;
        button {
          width: 100%;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
}
.privacy {
  padding-bottom: 100px;
  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
  .content {
    .points {
      h3 {
        color: #101828;
        font-family: "Mona-SemiBold";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        margin: 0px;
        margin-bottom: 15px;
        margin-top: 50px;
      }
      h5 {
        font-size: 22px;
        font-family: "Mona-SemiBold";
        margin-bottom: 8px;
      }
      p {
        color: #475467;
        font-family: "Mona-Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 0px;
        margin-bottom: 15px;
        b {
          font-family: "Mona-SemiBold";
        }
        a {
          text-decoration: none;
          color: #1943dc;
        }
        span {
          display: block;
        }
      }
      ul {
        padding-left: 15px;
      }
    }
  }
  .Desc {
    p {
      color: #475467;
      font-family: "Mona-Regular";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 0px;
    }
  }
  .Heading {
    padding-top: 40px;
    padding-bottom: 20px;
    h4 {
      color: #101828;
      font-family: "Mona-SemiBold";
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      margin: 0px;
    }
    h5 {
      color: #101828;
      font-family: "Mona-SemiBold";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin: 0px;
    }
  }
}
.career-values {
  @media (max-width: 992px) {
    padding-top: 0px;
  }
  .value-cards {
    .card-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-top: 50px;
      @media (max-width: 992px) {
        margin-top: 20px;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      .card {
        // flex-basis: calc(50% - 30px);
        &:first-child {
          margin-top: 0px;
          transition: 0.5s ease;
        }
      }
    }
  }
}
