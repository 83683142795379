.secondary-button {
  border-radius: 4px;
  border: 1px solid rgba(50, 50, 50, 10%);
  background-color: #ffffff;
  color: #1943dc;
  font-size: 16px;
  font-family: "Mona-SemiBold";
  line-height: 15px;
  padding: 11px 15px;
  cursor: pointer;
  transition: 0.3s linear;
  display: flex;
  align-items: center;
  height: 42px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &.marginTop {
    margin-top: 24px;
  }
  span {
    padding-left: 12px;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    background: #1943dc;
    transition: 0.6s ease-out;
    z-index: -1;
  }
  &::before {
    content: "";
    left: -2px;
  }
  &::after {
    content: "";
    right: -2px;
  }
  &:hover {
    color: #ffffff;
    span {
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
    &::before {
      width: 52%;
    }
    &::after {
      width: 52%;
    }
  }
  @media (max-width: 580px) {
    font-size: 14px;
  }
}
