.forgot {
  &.check-mail {
    .left-content {
      .content {
        background: url("../../../assets/images/auth/checkMail.svg");
        background-repeat: no-repeat;
        background-position: center top;
      }
    }
  }
  &.reset {
    .left-content {
      .content {
        background: url("../../../assets/images/auth/newPassBg.svg");
        background-repeat: no-repeat;
        background-position: center -10px;
        background-size: 160%;
        form {
          .input {
            margin-top: 0px;
          }
          ul {
            text-align: left;
            list-style: none;
            li {
              padding-bottom: 10px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }
    }
  }
  .left-content {
    .content {
      background: url("../../../assets/images/auth/forgotBg.svg");
      background-repeat: no-repeat;
      justify-content: center !important;
      background-position: center top;
      margin-left: 0;
      max-width: 100%;
      form {
        width: 100%;
        max-width: 360px;
        margin-inline: auto;
        text-align: center;
        padding-inline: 10px;
        .input {
          margin-top: 30px;
          label {
            text-align: left;
          }
        }
        button {
          margin-bottom: 30px;
        }
        a {
          font-size: 14px;
          line-height: 20px;
          font-family: "Mona-SemiBold";
          display: flex;
          align-items: center;
          gap: 10px;
          color: #475467;
          justify-content: center;
          text-decoration: none;
        }
        .resend {
          font-size: 14px;
          line-height: 20px;
          font-family: "Mona-Regular";
          color: #475467;
          display: flex;
          text-align: center;
          justify-content: center;
          gap: 5px;
          a {
            font-family: "Mona-SemiBold";
            color: #1943dc;
          }
        }
      }
    }
  }
}
