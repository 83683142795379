* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
}
body {
  margin: 0;
  font-family: "Mona-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

@font-face {
  font-family: "Mona-Regular";
  src: url("./assets/fonts/mona/Mona-Sans-Regular.woff") format("woff");
}
@font-face {
  font-family: "Mona-Medium";
  src: url("./assets/fonts/mona/Mona-Sans-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Mona-SemiBold";
  src: url("./assets/fonts/mona/Mona-Sans-SemiBold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Mona-Bold";
  src: url("./assets/fonts/mona/Mona-Sans-Bold.woff") format("woff");
}

.hide-scroll {
  overflow: hidden;
  position: relative;
}
.hide-scroll::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  position: absolute;
  top: 0;
}
.container {
  padding-inline: 20px;
  max-width: 1240px;
  margin-inline: auto;
  width: 100%;
}
.section {
  padding-block: 100px;
}
.footer-update {
  margin-bottom: 15px;
  width: max-content;
  background-color: #edeffe;
  border: 1px solid #e3e5fd;
  border-radius: 4px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.footer-update span {
  color: #1943dc;
  font-size: 14px;
  font-family: "Mona-Medium";
  letter-spacing: 0.2px;
  transition: 0.3s linear;
}
@media (max-width: 768px) {
  .section {
    padding-block: 50px;
  }
}
@media (max-width: 580px) {
  .footer-update {
    padding-inline: 8px;
    gap: 5px;
  }
}

input,
select,
textarea {
  outline: none;
}
